import React, { useState } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Container from "../container/container"
import Input from "../form/Input"
import { toast } from "react-toastify"
import Button from "../button/button"

const schema = yup
  .object({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required"),
    phone: yup.string().required("Phone Number is required"),
  })
  .required()

const ContactForm = () => {
  const methods = useForm({
    resolver: yupResolver(schema),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods

  const [processingForm, setProcessingForm] = useState(false)
  const [submissionCompleted, setSubmissionCompleted] = useState(false)

  const onSubmit = async (data) => {
    setProcessingForm(true)

    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")

    const response = await fetch(process.env.GATSBY_MAKE_CONTACT_WEB_HOOK, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status === 200) {
        toast.success("Successfully submitted")
        setProcessingForm(false)
        setSubmissionCompleted(true)
      } else {
        toast.error("Something went wrong. Please try again!")
        setProcessingForm(false)
      }
    })
  }

  return (
    <Container pt={0} pb={0}>
      {submissionCompleted ? (
        <p>Thank you for your submission. We'll be in touch with you soon.</p>
      ) : (
        <FormProvider {...methods}>
          <form
            className="form__contact"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <Input
              label={"Name"}
              name="name"
              {...register("name")}
              required
              errorMessage={errors.name?.message}
              secondary
              light
            />
            <Input
              label={"Email"}
              name="email"
              {...register("email")}
              required
              errorMessage={errors.email?.message}
              secondary
              light
            />
            <Input
              label={"Phone Number"}
              name="phone"
              {...register("phone")}
              required
              secondary
              light
              errorMessage={errors.phone?.message}
            />
            <Input
              label={"Message"}
              name="message"
              {...register("message")}
              multiline
              secondary
              light
            />
            <Container pt={1}>
              <Button light type="submit">
                {processingForm ? "Processing...." : "Submit"}
              </Button>
            </Container>
          </form>
        </FormProvider>
      )}
    </Container>
  )
}
export default ContactForm
