import React from "react"
import Container from "../container/container"
import style from "./breadcrumbs.mod.scss"
import Navigator from "./navigator"

const Breadcrumbs = ({ items }) => {
  return (
    <div className={style.breadcrumbs}>
      <Container gutters width={"medium"}>
        <div className={style.breadcrumbs__wrap}>
          <div>
            <span className={style.breadcrumbs__item}>
              <Navigator customPath={"/"}>{"Home"}</Navigator>
              <span>{"/"}</span>
            </span>
            {items.map(({ title, id }) => (
              <span className={style.breadcrumbs__item}>
                <Navigator recordId={id}>{title}</Navigator>
                <span className={style.breadcrumbs__divider}>{"/"}</span>
              </span>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Breadcrumbs
