import React from "react"
import style from "./input.mod.scss"
import textAreaStyle from "./textarea.mod.scss"

const Input = React.forwardRef(
  (
    {
      label,
      name,
      type = "text",
      placeholder,
      errorMessage,
      required,
      pattern,
      onBlur,
      value,
      hidden,
      fullWidth,
      disabled = false,
      ariaLabel,
      onChange,
      onFocus,
      icon,
      onIconClick,
      multiline = false,
      autoComplete = "on",
      reverseIcon = false,
      readOnly = false,
      secondary = false,
      light = false,
      small = false
    },
    ref
  ) => {
    const modifiers = []
    errorMessage && modifiers.push(style["input--error"])
    fullWidth && modifiers.push(style["input--full-width"])
    icon && modifiers.push(style["input--with-icon"])
    reverseIcon && modifiers.push(style["input--with-icon-reverse"])
    secondary && modifiers.push(style["input--style-secondary"])
    light && modifiers.push(style["input--style-light"])
    secondary && modifiers.push(textAreaStyle["textarea--style-secondary"])
    light && modifiers.push(textAreaStyle["textarea--style-light"])
    small && modifiers.push(style["input--small"])
    placeholder && modifiers.push(style["input--show-placeholder"])

    const inputConfig = {
      id: `input--${name}`,
      type,
      name,
      ref,
      pattern,
      placeholder,
      required,
      hidden,
      value,
      onChange,
      onBlur,
      onFocus,
      autoComplete
    }

    if (multiline) {
      return (
        <div className={[textAreaStyle.textarea, ...modifiers].join(' ')}>
          {label && (
            <label htmlFor={`input--${name}`}>
              {label}
              {required && <span aria-label="required">*</span>}
            </label>
          )}
          <textarea {...inputConfig} />
        </div>
      )
    }

    return (
      <div className={[style.input, ...modifiers].join(" ")}>
        {label && (
          <label htmlFor={`input--${name}`}>
            {label}
            {required && <span aria-label="required">{`*`}</span>}
          </label>
        )}
        {multiline ? (
          <textarea {...inputConfig} />
        ) : (
          <input {...inputConfig} disabled={disabled} readOnly={readOnly} aria-label={ariaLabel} />
        )}
        {errorMessage && (
          <div className={style.input__error}>
            {errorMessage}
          </div>
        )}
      </div>
    )
  }
)

export default Input
