import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import BorderWrapper from "../components/border-wrapper/border-wrapper"
import Breadcrumbs from "../components/global/breadcrumbs"
import Container from "../components/container/container"
import ContactForm from "../components/contact/ContactForm"
import style from "../components/contact/contact.mod.scss"
import ProductSlider from "../components/product/product-slider"

const Wineries = ({
  data: {
    datoCmsContactPage: { id, title },
    recommended,
  },
}) => {
  const breadcrumbs = [{ title: title, id: id }]

  return (
    <Layout>
      <BorderWrapper topColor={"light"} bottomColor={"light"}>
        {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
        <div className={style.contact}>
          <div>
            <Container width="small" gutters pt={2}>
              <div className={style.contact__inner}>
                <h1>{title}</h1>
                <ContactForm />
              </div>
            </Container>
          </div>
        </div>
      </BorderWrapper>

      <Container pt={2} pb={0} bg={"light"} width={"none"}>
        <ProductSlider
          heading={"Highly Recommended"}
          subHeading={"Our wines"}
          items={recommended.nodes}
        />
      </Container>
    </Layout>
  )
}

export default Wineries

export const query = graphql`
  {
    datoCmsContactPage {
      id: originalId
      title
    }
    recommended: allPortalWine(
      filter: { wine_tags: { elemMatch: { name: { eq: "Highly Recommend" } } } }
    ) {
      nodes {
        id
        name
        price
        rrp
        discounted_price
        image
        winery {
          id
          minimum_wines_per_order
        }
      }
    }
  }
`
